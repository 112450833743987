<template>
    <div class="classify-two-all" >
        <index-header :title="$route.query.title"></index-header>

        <van-list  v-model="loading" :finished="finished" finished-text="......" :immediate-check="false" loading-text="loading..." @load="onLoad">

            <goods-columns @cartClick="handleCart" :data="goodsList" :loading="goodsLoad" :columns="1"></goods-columns>

        </van-list>
    
        <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

        <!-- 选择SKU 加入购物车 -->
        <m-sku title="Add to Cart" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

        <!-- 加入购物车动画 -->
        <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    </div>
</template>


<script>
import IndexHeader from '@/components/en/m-header.vue'
import GoodsColumns from '@/components/en/goods-columns.vue'
import MSku         from '@/components/en/m-sku.vue'
import MAnimate     from '@/components/en/cart-animate.vue'

import { classify_goods } from '@/api/zh/merchant.js'

export default {
    components: { IndexHeader, GoodsColumns, MSku, MAnimate },
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            skuModal   : false,    // 控制显示 选择sku加入购物车面板
            loading    : false,
            islock     : false,     // 防止多次加载
            start      : false,      // 是否开启加入购物车动画
            start_dom  : null,   // 开启动画的起点DOM


            goodsList  : [],
            page       : 1,
            finished:false,
            goodsLoad: false,
 
            goodsId    : ''
        }
    },
    methods: {
      
      
  
        // 商品列表购物车点击事件
        handleCart(goods,e){
            this.goodsId = goods
            this.skuModal  = true
            this.start_dom = e
        },
        // sku 弹窗加入购物车成功回调
        handleCartSuccess(){
            this.skuModal = false
            let timer = setTimeout( () => {
                this.start = true
                clearTimeout(timer)
            }, 300)
        },
        // 加入购物车动画结束
        handleCartEnd(){
            this.start = false
        },

        getGoods() {
            let params = {
                shops_id: this.$route.query.id,
                type_id: this.$route.query.tid,
                page: this.page
            }
            this.goodsLoad = true
            classify_goods(params).then(res => {
                if(res) {
                    this.goodsList = this.goodsList.concat(res.data.data)
                    if(this.page >= res.data.last_page) {
                        this.finished = true
                    }
                }
            }).finally(() => {
                this.loading = false
                this.goodsLoad = false
            } )
        },

        // 上拉加载
        onLoad(){
            this.page++
            this.getGoods()
        },
        
        
    },
    created() {
        this.getGoods()
    },

}
</script>


<style scoped lang="less">
.classify-two-all{
    height:100%;
    overflow-y: auto;
    padding-top: 44px;

}
</style>